import React from 'react'

const Terms = () => {
  return (
    <div className='terms max-w-[1200px] p-12 md:p-24'>
        <a className='relative block mb-8 underline' href='/'>go back</a>
        <div className='text-4xl mb-1'>Kibu Care Terms and Conditions</div>
        <div className='text-2xl mb-2'>Welcome to Kibu Care</div>
      Thank you for choosing Kibu’s sustainable and repairable children’s headphones. Kibu Care is our promise to you for the longevity of your headphones, ensuring they continue to serve your family well. Below are the terms and conditions for the Kibu Care service.
      <ol className='list-decimal mt-8 space-y-4'>
          <li><strong>Eligibility for Kibu Care</strong><br/>
              Kibu Care is offered to customers who have either purchased Kibu Care separately or received it as part of their initial Kibu headphones order. Eligibility requires a valid proof of purchase, which must indicate either the separate purchase of Kibu Care or its inclusion with the headphones. Service coverage begins from the purchase date listed on the proof of purchase.
          </li>
          <li><strong>Services Offered</strong>
              <ul>
                  <li><strong>Repair/Replacement Coverage:</strong> Kibu Care covers the repair or replacement of your Kibu headphones if they suffer functional failure or defects from normal use or accidental damage. This does not include failures or defects arising from intentional misuse or abuse.</li>
                  <li><strong>Delivery Fee:</strong> All repair and replacement services under Kibu Care are provided free of charge, except for the delivery fee for the return of the repaired or replacement item to the customer.</li>
                  <li><strong>Replacement Parts:</strong> We will match the original parts as closely as possible with functionally equivalent, possibly refurbished or recycled components. Minor aesthetic blemishes may be present due to the nature of using recycled materials.</li>
              </ul>
          </li>
          <li><strong>Claim Limitations</strong>
              <ul>
                  <li><strong>Cool Off Period:</strong> A 30-day cool off period is enforced after each claim to prevent service abuse. During this time, the claimant cannot initiate additional claims. We reserve the right to apply this cool off period at our discretion to ensure fair service access.</li>
              </ul>
          </li>
          <li><strong>General Conditions</strong>
              <ul>
                  <li><strong>Coverage Period:</strong> The specific duration of Kibu Care coverage is defined at purchase and begins on the purchase date.</li>
                  <li><strong>Non-Transferability:</strong> Kibu Care is non-transferable and valid only for the original purchaser.</li>
                  <li><strong>Exclusions:</strong> Kibu Care does not cover damages from intentional misuse or unauthorized modifications. Normal wear and tear and accidental damage are covered, distinguishing from acts that purposely aim to break the product.</li>
              </ul>
          </li>
          <li><strong>Making a Claim</strong><br/>
              To make a claim under Kibu Care, contact our customer service with your proof of purchase and a detailed account of the issue. Our team will provide the necessary guidance and support through the claim process.
          </li>
          <li><strong>Acceptance of Terms</strong><br/>
              Use of Kibu Care services constitutes agreement to these terms and conditions, which we may update from time to time. Continued use after updates signifies acceptance of new terms.
          </li>
      </ol>
    </div>
  )
}

export default Terms