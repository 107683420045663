import React from 'react'

const ReferralTerms = () => {
  return (
    <div className='terms max-w-[1200px] p-12 md:p-24'>
        <a className='relative block mb-8 underline' href='/referral'>go back</a>
        <div className='text-4xl mb-1'>Kibu Kickstarter Referral Program Terms and Conditions</div>
        <div className='text-2xl mb-2'>Introduction</div>
        Welcome to the Kibu Kickstarter Referral Program! We appreciate your interest in promoting our innovative, sustainable children’s headphones. By participating in this program, you can earn rewards by referring new backers to our Kickstarter campaign.
        <ol className='list-decimal mt-8 space-y-4'>
            <li><strong>Eligibility</strong><br/>
                To participate in the Kibu Kickstarter Referral Program, you must be a registered user on Kickstarter and agree to these terms and conditions. You must also have a valid and operational PayPal account to receive payments.
            </li>
            <li><strong>Referral Links</strong><br/>
                Upon joining the program, you will receive a unique referral link from us. This link can be shared with your personal network through social media, email, or other communication methods. You are encouraged to share your referral link responsibly.
            </li>
            <li><strong>Rewards</strong><br/>
                For each qualifying sale made through your referral link, you will earn £5. The minimum purchase amount for a sale to qualify is £39. Only sales verified by Kickstarter as originating from your referral link will count towards your rewards.
            </li>
            <li><strong>Payment</strong><br/>
                Payments will be made within 30 days following the completion of our Kickstarter campaign. Payments will be processed through PayPal, and you must provide accurate PayPal account information to receive your earnings.
            </li>
            <li><strong>Chargebacks and Fraud</strong><br/>
                Chargebacks and refunds on sales generated through referral links will result in the forfeiture of corresponding rewards. Repeated instances of chargebacks or suspicious activity may result in the termination of your participation in the referral program.
            </li>
            <li><strong>Penalties for Abuse</strong><br/>
                Any abuse of the referral program, including but not limited to, the creation of multiple accounts, fraud, or attempts to bypass or exploit the rules, will result in immediate disqualification and potential legal action.
            </li>
            <li><strong>Termination and Changes</strong><br/>
                Kibu reserves the right to terminate the referral program or a participant’s involvement at any time without notice, for any reason, including but not limited to a breach of these terms. We also reserve the right to modify these terms and conditions at any time. Changes will be posted publicly and will be effective immediately unless stated otherwise.
            </li>
            <li><strong>Acceptance of Terms</strong><br/>
                By participating in the Kibu Kickstarter Referral Program, you agree to these terms and conditions. Your continued participation in the program constitutes acceptance of any changes to these terms.
            </li>
        </ol>
    </div>
  )
}

export default ReferralTerms