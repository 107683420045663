import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router-dom";
import SectionLayout from '@/components/SectionLayout'

const Referral = () => {
  const navigate = useNavigate()
  const [thankYou, setThankYou] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [checkEmail, setCheckEmail] = useState(false)
  const [termsAgreed, setTermsAgreed] = useState(false)
  const emailInput = useRef(null)

  const handleEmailChange = (event) => {
    const email = event.target.value;
    // Simple email validation - you can replace this with more complex validation
    const isValid = email.includes('@') && email.includes('.');
    setIsEmailValid(isValid);
  };

  const handleSignUpClick = (e) => {
    e.preventDefault();
    if (formValid) {
      // send email to mongoDB
      // then
      setThankYou(true)
    } else {
      setCheckEmail(true)
    }
  }

  useEffect(() => {
    if (isEmailValid && termsAgreed) {
      setFormValid(true)
    } else {
      setFormValid(false)
    }
  }, [isEmailValid, termsAgreed])


  return (
    <div className='view referral'>
      {thankYou ? 
        <SectionLayout backgroundColor='bg-palegrey' index={1} noScrollSnap>
          <main className='flex flex-col justify-center gap-2 items-center grow'>
          <div className='w-16 h-32 cursor-pointer' onClick={() => navigate('/')}>
              <img src='./kibu-logo-orange.svg' className='object-scale-down' />
            </div>
            <div className='max-w-96 text-center'>
              Thank you. We'll be in contact shortly.
            </div>
          </main>
        </SectionLayout>
        :
        <SectionLayout backgroundColor='bg-palegrey' index={1} noScrollSnap>
          <main className='flex flex-col justify-center gap-2 items-center grow'>
            <div className='w-16 h-32 cursor-pointer' onClick={() => navigate('/')}>
              <img src='./kibu-logo-orange.svg' className='object-scale-down' />
            </div>
            <div className='max-w-96 text-center'>
              <div className='text-bold text-2xl mb-2'>Kickstarter Referral Program</div>
              Please enter your e-mail address below and we will send your unique referral link as soon as possible. <br/> <br/>You can use the link to refer others to our campaign. Once they contribute, you'll be eligible to claim a reward.
            </div>
            <input ref={emailInput} autoFocus className={`input-field min-w-80 mt-8 rounded-xl p-4 border ${checkEmail ? 'border-red-300 bg-red-50/20' : 'border-transparent'}`} type="text" name="EMAIL" placeholder="Email" required onChange={handleEmailChange}/>
            <button disabled={!formValid} onClick={handleSignUpClick} className='bg-neworange rounded-xl p-4 text-white transition-all duration-300 ease-out-expo hover:shadow-bshadowhover hover:rounded-2xl disabled:opacity-30 disabled:pointer-events-none'>
              Request Unique Referral Link
            </button>
            <label>
              <input type="checkbox" checked={termsAgreed} onChange={() => setTermsAgreed(!termsAgreed)} class="neworange" /> Accept <a href='/referral-terms' className='underline'>Terms & Conditions</a>
            </label>
          </main>
        </SectionLayout>
      }
    </div>
  )
}

export default Referral