import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useLocation } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

const TRACKING_ID = "G-VRV2VT8TT2"; // OUR_TRACKING_ID
var host = window.location.hostname;

if(host != "localhost") {
  ReactGA.initialize(TRACKING_ID);
}


const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: true, // enable logs
};

ReactPixel.init('YOUR_PIXEL_ID', null, options);
ReactPixel.pageView(); // For tracking page view


// A component to track pageviews on route change
const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Send pageview with new path
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null; // This component doesn't render anything
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <RouteChangeTracker />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
