import React, {useState, useEffect, useRef} from 'react'

const SectionLayout = ({backgroundColor, className, index, parentDivRef, noScrollSnap, children}) => {
  const [inViewport, setInViewPort] = useState(false);
  const viewportHeight = window.innerHeight;
  const [isSafariIOS, setIsSafariIOS] = useState(false);

  const isSafariOnIOS = () => {
    const userAgent = window.navigator.userAgent;
    const iOS = !!userAgent.match(/iPad/i) || !!userAgent.match(/iPhone/i);
    const webkit = !!userAgent.match(/WebKit/i);
    return iOS && webkit && !userAgent.match(/CriOS/i);
  };

  const checkScroll = () => {
    if (window.scrollY >= viewportHeight * index - 50) {
      setInViewPort(true);
  } else {
      setInViewPort(false);
  }
  }

  useEffect(() => {
    setIsSafariIOS(isSafariOnIOS());
    if (index === 0) {
      setInViewPort(true);
    }
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [])
  return (
    <div className={`${className} layout section ${inViewport ? 'visible' : ''} w-full h-screen min-h-screen max-h-screen pt-4 pl-4 pr-4 ${(isSafariIOS && index == 0) ? 'pb-24' : 'pb-4'} md:p-8`} style={{scrollSnapAlign: noScrollSnap ? 'none' : 'start'}}>
      <div className={`inner ${backgroundColor} relative rounded-3xl h-full max-h-full overflow-hidden`}>
        {/* <div className='media w-full h-full absolute t-0 l-0 r-0 b-0 z-0'></div> */}
        <div className={`content p-6 md:p-10 h-full z-10 flex flex-col overflow-hidden max-h-full relative`}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default SectionLayout