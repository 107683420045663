import React, { useState, useEffect, useRef } from 'react'
import SectionLayout from '@/components/SectionLayout'


const Home = () => {
  let isMobile = false;
  const mediaQuery = window.matchMedia('(max-width: 812px)');
  isMobile = mediaQuery.matches;

  const [utmParameters, setUtmParameters] = useState({
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUtmParameters({
      utm_source: urlParams.get('utm_source') || '',
      utm_medium: urlParams.get('utm_medium') || '',
      utm_campaign: urlParams.get('utm_campaign') || '',
    });
  }, []);

  const [signupVisible, setSignupVisible] = useState(false)
  const [signupSuccessful, setSignupSuccessful] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [checkEmail, setCheckEmail] = useState(false)
  const formRef = useRef(null);

  const handleEmailChange = (event) => {
    const email = event.target.value;
    // Simple email validation - you can replace this with more complex validation
    const isValid = email.includes('@') && email.includes('.');
    console.log(isValid)
    setIsEmailValid(isValid);
  };

  const handleSignUpClick = (e) => {
    e.preventDefault();
    if (isEmailValid) {
      formRef.current.submit()
      setTimeout(() => {
        setSignupVisible(false)
        setSignupSuccessful(true)
      }, 150)
    } else {
      setCheckEmail(true)
    }
  }

  const emailInput = useRef(null)

  useEffect(() => {
    if (signupVisible) {

    }
  }, [signupVisible])



  const parentDivRef = useRef(null);
  const [videoVisible, setVideoVisible] = useState(false)

  const triggerVideo = () => {
    if (videoVisible) {
      setVideoVisible(false)
    } else {
      setVideoVisible(true)
    }
  }

  const [introVideoFinished, setIntroVideoFinished] = useState(false)

  const [selectedQuote, setSelectedQuote] = useState(0)
  const quotes = [
    {
      quote: 'Positively designed as an educational tool for a new generation of responsible consumers.',
      author: 'design wanted',
      link: 'https://designwanted.com/kibu-headphones-children-built-repaired-recycled/',
      logo: '/assets/'
    },
    {
      quote: 'These customisable children’s headphones are a first step into the essential world of circular design.',
      author: 'Wallpaper Magazine',
      link: 'https://www.wallpaper.com/tech/kibu-headphones',
      logo: '/assets/'
    },
    {
      quote: 'We need more ingenious designs like this for products that respect our planet.',
      author: 'The Guardian',
      link: 'https://www.theguardian.com/artanddesign/2023/dec/13/best-designs-and-designers-of-2023-a-chunk-of-glossy-sexiness',
      logo: '/assets/'
    },
    {
      quote: 'These customisable headphones are a first step into the essential world of circular design.',
      author: 'Design Week',
      link: 'https://www.designweek.co.uk/issues/05-june-09-june-2023/morrama-batch-works-kibu-headphones/',
      logo: '',
    },
    {
      quote: 'These 100% replaceable, recyclable headphones are too nice to be just for kids.',
      author: 'Techradar',
      link: 'https://www.techradar.com/news/these-100-replaceable-recyclable-headphones-are-too-nice-to-be-just-for-kids',
      logo: '',
    }
  ]

  let autoSlider;
  useEffect(() => {
    autoSlider = setTimeout(() => {
      setSelectedQuote(selectedQuote < quotes.length - 1 ? selectedQuote + 1 : 0)
    }, 8000)
  }, [selectedQuote])

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <div ref={parentDivRef} className={`view home h-auto ${videoVisible ? 'max-h-screen overflow-hidden' : ''}`}>
      <button onClick={() => videoVisible ? triggerVideo() : openInNewTab('https://configure.kibu.family')} className={`fixed z-20  md:right-16 ${videoVisible ? 'bg-black top-6 right-6 md:top-12 delay-500' : 'bg-neworange right-8 top-8 md:top-16'} shadow-bshadow rounded-xl p-4 text-white transition-all duration-300 ease-out-expo hover:shadow-bshadowhover hover:bg-neworangehover hover:rounded-2xl`}>
        {videoVisible ?
          'Close Video'
          :
          'Pre Order Now'
        }
      </button>
      <SectionLayout backgroundColor='bg-neworange' className={`scroll-initiated-scale ${videoVisible ? 'flipped' : ''}`} index={0} parentDivRef={parentDivRef}>
        <div className='kickstarter-video absolute p-6 z-30 opacity-0 left-0 top-0 w-full h-full items-center pointer-events-none'>
          {/* <iframe width="100%"  allowFullScreen className='pointer-events-none object-fit' src="https://www.kickstarter.com/projects/titaner-us/mix-20-titaner-titanium-mechanism-edc-ruler/widget/video.html" frameborder="0" scrolling="no"> </iframe> */}
          <iframe width="100%" height="100%" className='pointer-events-none object-fit' src="https://www.youtube.com/embed/VEkfK53IBoA?si=hww1lFG8XS4uD6G9" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
        <header className='flex justify-between items-center relative relative z-10'>
          <img src='./kibu-logo.svg' width={isMobile ? 30 : 50} className='scroll-initiated-element' />
        </header>
        <div className={`animation flex-1 absolute w-full h-full z-0 left-0 top-0 flex flex-col justify-end`}>
          <div className='absolute w-full h-full left-0 top-0 right-0 bottom-0 flex flex-col justify-center'>
            <video className={`${isMobile ? 'w-full h-auto' : 'w-full h-full'} object-cover`}
              id="background-video"
              autoPlay
              playsInline
              muted
              onEnded={() => setIntroVideoFinished(true)}>
              {isMobile ?
                <source src="/assets/kibu-intro-mobile.mp4" type="video/mp4" />
                :
                <source src="/assets/kibu-intro.mp4" type="video/mp4" />
              }
              Your browser does not support the video tag.
            </video>
            <img className={`${isMobile ? 'w-full h-auto' : 'w-full h-full'} transition-all duration-100 object-cover absolute  ${introVideoFinished ? 'opacity-100' : 'opacity-0'}`}
              src={isMobile ? '/assets/kibu-intro-mobile.jpg' : '/assets/kibu-intro.jpg'} />
          </div>
        </div>
        <main className={`flex justify-between gap-24 relative grow ${isMobile ? 'pt-28' : 'items-center'}`}>
          <div className={`scroll-initiated-element md:self-end flex justify-center items-center flex-3 max-w-full z-0 ${isMobile ? 'absolute' : ''}`} >

          </div>
          <div className={`big-headline scroll-initiated-element flex-2 z-10 text-5xl text-center md:text-left md:text-6xl lg:text-7xl 2xl:text-8xl text-white leading-none font-medium ${isMobile ? 'text-drop-shadow' : ''}`}
            style={{ transitionDelay: isMobile ? '3500ms' : '1100ms' }}>
            Sustainable Children's Headphones
          </div>
        </main>
        <footer className='text-white flex justify-center md:justify-end text-lg'>
          <div className='scroll-initiated-element flex flex-col md:flex-row gap-1 items-center group cursor-pointer'
            onClick={() => triggerVideo()}>
            <div className='group-hover:underline text-center'>Watch video</div>
            <div className='border border-white p-1 rounded-2xl max-w-32 relative group-hover:border-white/30 transition-all duration-120'>
              <div className='absolute w-3 h-3 -left-1 ml-[50%] -top-1 mt-[28%] z-10 opacity-70 group-hover:opacity-100'>
                <svg width="14" height="17" viewBox="0 0 19 21" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 8.76795C19.3333 9.53775 19.3333 11.4623 18 12.2321L3.75 20.4593C2.41666 21.2291 0.749999 20.2668 0.749999 18.7272L0.75 2.27276C0.75 0.733156 2.41667 -0.229094 3.75 0.540707L18 8.76795Z" fill="white" />
                </svg>
              </div>
              <div className='rounded-xl relative z-0 overflow-hidden group-hover:brightness-50 transition-all duration-120'>
                <img src='/assets/video-preview.jpg' className='' />
              </div>
            </div>
          </div>
        </footer>
      </SectionLayout>

      <SectionLayout backgroundColor='bg-palegrey' index={1} parentDivRef={parentDivRef}>
        <div className={`animation flex-1 absolute w-full h-full z-0 left-0 top-0`}>
          <video id="background-video" className='w-full h-full object-cover' loop autoPlay playsInline muted>
            {isMobile ?
              <source src="/assets/build-video-mobile.mp4" type="video/mp4" />
              : <source src="/assets/build-video.mp4" type="video/mp4" />
            }
            Your browser does not support the video tag.
          </video>
        </div>
        <main className='flex flex-col justify-center gap-1 items-center grow z-10'>
          <div className='small-headline text-neworange text-xl md:text-2xl 2xl:text-4xl text-center'>Build</div>
          <div className='big-headline text-3xl text-center md:text-6xl 2xl:text-7xl text-white font-medium text-drop-shadow leading-none text-shadow-headlineshadow'>Create Sound <br />Piece by Piece</div>
        </main>
      </SectionLayout>

      <SectionLayout backgroundColor='bg-paleblue' index={2} parentDivRef={parentDivRef}>
        <div className={`animation flex-1 absolute w-full h-full z-0 left-0 top-0`}>
          <video id="background-video" className='w-full h-full object-cover' loop autoPlay playsInline muted>
            {isMobile ?
              <source src="/assets/kibu-repair-mobile.mp4" type="video/mp4" />
              : <source src="/assets/kibu-repair.mp4" type="video/mp4" />
            }
            Your browser does not support the video tag.
          </video>
        </div>
        <main className='flex flex-col justify-center gap-1 items-center grow z-10 pb-80'>
          <div className='small-headline text-blue text-xl md:text-2xl 2xl:text-4xl text-center'>Repair</div>
          <div className='big-headline text-3xl text-center md:text-6xl 2xl:text-7xl text-black font-medium leading-none text-shadow-headlineshadow'>Fixes Made <br />Child's Play</div>
        </main>
      </SectionLayout>

      <SectionLayout backgroundColor='bg-palegreen' index={3} parentDivRef={parentDivRef}>
        <div className={`animation flex-1 absolute w-full h-full z-0 left-0 top-0`}>
          <video id="background-video" className='w-full h-full relative object-cover' loop autoPlay playsInline muted>
            {isMobile ?
              <source src="/assets/recycle-mobile.mp4" type="video/mp4" />
              : <source src="/assets/recycle.mp4" type="video/mp4" />
            }
            Your browser does not support the video tag.
          </video>
        </div>
        <main className='flex flex-col justify-center gap-1 items-center grow pb-10 z-10'>
          <div className='small-headline text-green text-xl md:text-2xl 2xl:text-4xl text-center'>Recycle</div>
          <div className='big-headline text-3xl text-center md:text-6xl 2xl:text-7xl font-medium leading-none text-shadow-headlineshadow'>Circular<br />Sound</div>
        </main>
      </SectionLayout>

      <SectionLayout backgroundColor='bg-safety' index={4} parentDivRef={parentDivRef}>
        <div className={`animation flex-1 absolute w-full h-full bg-safety z-0 left-0 top-0`}>
          <video id="background-video" className='w-full h-full relative object-cover' loop autoPlay playsInline muted>
            {isMobile ?
              <source src="/assets/safety-mobile.mp4" type="video/mp4" />
              : <source src="/assets/safety.mp4" type="video/mp4" />
            }
            Your browser does not support the video tag.
          </video>
        </div>
        <main className='flex flex-col justify-center gap-1 items-center grow z-10 pb-60 md:pb-96'>
          <div className='small-headline text-grey text-xl md:text-2xl 2xl:text-4xl text-center'>Kibu Safety Engine</div>
          <div className='big-headline text-3xl text-center md:text-6xl 2xl:text-7xl text-black font-medium leading-none text-drop-shadow-white'>Safe Listening, No <br />Matter the Device</div>
        </main>
      </SectionLayout>

      <SectionLayout backgroundColor='bg-palepink' index={5} parentDivRef={parentDivRef}>
        <div className={`animation flex-1 absolute w-full h-full z-0 left-0 top-0`}>
          {/* <video id="background-video" className='w-full h-full object-cover' loop autoPlay playsInline>
              <source src="/assets/build-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
          </video> */}
          <img src='/assets/daisy.png' className='w-full h-full object-cover' />
        </div>
        <main className='flex flex-col justify-center gap-1 items-center grow z-10'>
          <div className='small-headline text-grey text-xl md:text-2xl 2xl:text-4xl text-center'>Daisy Chain</div>
          <div className='big-headline text-3xl text-center md:text-6xl 2xl:text-7xl text-black font-medium leading-none text-shadow-headlineshadow'>Connect together, <br />Listen Together</div>
        </main>
      </SectionLayout>

      <SectionLayout backgroundColor='bg-transparent border border-grey/30' index={6} parentDivRef={parentDivRef}>
        <header className='flex justify-between items-center relative text-xl md:text-2xl 2xl:text-4xl text-grey leading-none'>
          <div className='max-w-8 md:max-w-full'>What Journalists Say About Kibu</div>
        </header>
        <main className='justify-center grow overfow-hidden relative'>
          <div className='quote-copy-container flex flex-col absolute top-0' style={{
            height: (100 * (quotes.length)) + '%'
          }}>
            {quotes.map((quote, index) => (
              <div className={`quote-copy flex-1 flex flex-col items-center justify-center transition-all duration-quotes ease-in-out-cubic relative`} key={index} style={{
                transform: 'translateY(-' + 100 * selectedQuote + '%)',
              }}>
                <div className='flex flex-col gap-16'>
                  <div className='actual-copy text-2xl text-center md:text-left md:text-6xl 2xl:text-7xl text-black font-medium leading-none'>
                    “{quote.quote}”
                  </div>
                  {quote.author ? <a href={quote.link} target='_blank' className='text-xl text-center md:text-2xl 2xl:text-4xl md:text-left underline'>– {quote.author}</a> : null}
                </div>
              </div>
            ))}
          </div>
        </main>
      </SectionLayout>

      <SectionLayout backgroundColor='bg-palegrey' index={7} parentDivRef={parentDivRef}>
        <header className='flex justify-between items-center relative text-xl md:text-2xl 2xl:text-4xl text-grey'>
          What to expect
        </header>
        <main className='justify-center gap-24 grow overfow-hidden relative'>
          <ul className='list-disc list-inside leading-none text-black text-xl md:text-2xl 2xl:text-4xl space-y-6 md:space-y-6 2xl:space-y-10 mt-12'>
            <li className='border-t-2 border-grey pt-1 md:pt-1 2xl:pt-3'>Pair of modular headphones that come as a kit to be built</li>
            <li className='border-t-2 border-grey pt-1 md:pt-1 2xl:pt-3'>Made from recycled and recyclable PLA in Hackney, London</li>
            <li className='border-t-2 border-grey pt-1 md:pt-1 2xl:pt-3'>Kibu Safety Engine: actually limiting the sound to 85db</li>
            <li className='border-t-2 border-grey pt-1 md:pt-1 2xl:pt-3'>At least 4 pairs of headphones can be chained together</li>
            <li className='border-t-2 border-grey pt-1 md:pt-1 2xl:pt-3'>Modular parts can be ordered and replaced</li>
          </ul>

        </main>
        <footer className='text-grey md:text-black flex flex-col gap-2 md:flex-row md:justify-between w-full md:justify-between items-center text-lg'>
          <a href='mailto:contact@kibu.family' className={`underline block ${signupVisible ? 'opacity-0' : ''}`}>Contact Us</a>
          {signupSuccessful ?
            <div className='text-neworange'>Thank you for signing up!</div>
            :
            signupVisible ?
              <div className={`${isMobile ? 'fixed top-0 left-0 w-full h-full bg-white/95 p-4' : ''}`}>
                <div className={`${isMobile ? 'relative top-32' : ''}`}>
                  <div className={`Title-Wrapper`}>
                    <h3 className="Signup-Title Animate-Title mb-4 text-center text-xl text-black md:text-5xl md:mb-8">If you want to stay<br />up-to-date: </h3>
                    <div className="Join-Others-Wrapper">
                      {/* <text className="Join-Others-Text">Join</text>
                      <div className={`Join-Others-Number-Wrapper`}>
                        <text className="Join-Others-Text Others-Number"> {currentCount}+ </text>
                      </div>
                      <text className="Join-Others-Text">who have joined the family.</text> */}
                    </div>
                    <form className="flex flex-col gap-2" ref={formRef} action="https://manage.kmail-lists.com/subscriptions/subscribe?a=THfwqL&g=Urv5r9" method="post" id="mc-embedded-subscribe-form" target="_blank">
                      <input ref={emailInput} autoFocus className={`input-field rounded-xl p-4 border ${checkEmail ? 'border-red-300 bg-red-50/20' : 'border-transparent'}`} type="text" name="EMAIL" placeholder="Email" required onChange={handleEmailChange} />
                      <input type="hidden" name="$source" value={utmParameters.utm_source} />
                      <input type="hidden" name="Medium" value={utmParameters.utm_medium} />
                      <input type="hidden" name="Campaign" value={utmParameters.utm_campaign} />
                      <div className='flex gap-1'>
                        <button onClick={handleSignUpClick} className='flex-2 bg-neworange rounded-xl p-4 text-white transition-all duration-300 ease-out-expo hover:shadow-bshadowhover hover:rounded-2xl'>
                          Sign up
                        </button>
                        <button onClick={() => { setSignupVisible(false); setSignupSuccessful(false); setCheckEmail(false) }} className='flex-1 bg-grey rounded-xl p-4 text-white transition-all duration-300 ease-out-expo hover:shadow-bshadowhover hover:rounded-2xl'>
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              :
              isMobile ?
                <a href='' onClick={(e) => {
                  e.preventDefault()
                  setSignupVisible(true)
                }} className={`underline block`}>Newsletter Signup</a>
                :
                <button onClick={() => setSignupVisible(true)} className={`bg-grey rounded-xl p-4 text-white transition-all duration-300 ease-out-expo hover:shadow-bshadowhover hover:bg-black hover:rounded-2xl`}>
                  Sign up for our newsletter
                </button>
          }
          <a href='/terms' className={`underline block ${signupVisible ? isMobile ? 'hidden' : 'opacity-0' : ''}`}>Terms & Conditions</a>
        </footer>
      </SectionLayout>
      <SectionLayout backgroundColor='bg-palegrey' index={1} parentDivRef={parentDivRef}>
        <div className={`animation flex-1 absolute w-full h-full z-0 left-0 top-0`}>
          <video id="background-video" className='w-full h-full object-cover' loop autoPlay playsInline muted>
            {isMobile ?
              <source src="/assets/va-mobile.mp4" type="video/mp4" />
              : <source src="/assets/va.mp4" type="video/mp4" />
            }
            Your browser does not support the video tag.
          </video>
        </div>
        <main className='flex flex-col justify-center gap-1 items-center grow z-10'>
          <div className='small-headline text-white/70 text-xl md:text-2xl 2xl:text-4xl text-center'>Young V&A x Kibu</div>
          <div className='big-headline text-3xl text-center md:text-6xl 2xl:text-7xl text-white font-medium text-drop-shadow leading-none text-shadow-headlineshadow'>The Shed Competition</div>
          <button onClick={() => openInNewTab('https://www.vam.ac.uk/articles/the-shed-competition')} className={`bg-white right-8 top-8 mt-6 md:mt-12 shadow-bshadow rounded-xl p-4 text-black transition-all duration-300 ease-out-expo hover:shadow-bshadowhover hover:bg-black hover:text-white hover:rounded-2xl`}>
            Get Involved
          </button>
        </main>
      </SectionLayout>
    </div>
  )
}

export default Home