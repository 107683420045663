import React from 'react'
import { Route, Routes, useNavigate, useLocation, Navigate, Outlet } from "react-router-dom";
import './App.css';

import Home from './views/Home';
import Terms from './views/Terms';
import Referral from './views/Referral';
import ReferralTerms from './views/ReferralTerms';


/* FINJI REPRESENT */

function App() {
  return (  
    <div className="kibu">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/thank-you-for-signing-up" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/referral-terms" element={<ReferralTerms />} />
      </Routes>
    </div>
  );
}

export default App;
